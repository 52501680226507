import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ArticleSectionNonCollapsible from '../article-section-non-collapsible';
import WYSIWYG from '../wysiwyg';
import styles from './content-block.module.scss';

const ContentBlockComponent = ({
  machine_name,
  modifier,
  mainTitle = false
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        allNodeContentBlock {
          edges {
            node {
              field_machine_name
              title
              body {
                processed
              }
            }
          }
        }
      }
    `
  );
  const contentBlock = data.allNodeContentBlock.edges.find(
    ({ node }) => machine_name === node.field_machine_name
  );
  return (
    <>
      {modifier === 'article' && (
        <ArticleSectionNonCollapsible title={contentBlock.node.title}>
          <WYSIWYG body={contentBlock.node.body.processed} />
        </ArticleSectionNonCollapsible>
      )}
      {!modifier && (
        <div className={styles.wrapper}>
          {mainTitle && contentBlock?.node?.title && (
            <h1 className={styles.title}>{contentBlock.node.title}</h1>
          )}
          <WYSIWYG
            className={styles.contentBody}
            body={contentBlock?.node?.body?.processed}
          />
        </div>
      )}
    </>
  );
};

export default ContentBlockComponent;
