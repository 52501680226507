import React from 'react';
import { graphql } from 'gatsby';
import { getArticleCategory } from '../utils';

import PageLayout from '../components/page-layout/page-layout';
import SEO from '../components/seo';
import ArticleLayout from '../components/article-layout';
import ArticleTitleCard from '../components/article-title-card';
import ArticleSection from '../components/article-section';
import ArticleSectionCollapsibleBasic from '../components/article-section-collapsible-basic';
import { TocTrackedH2 } from '../components/toc-tracked-heading';
import ContentBlockComponent from '../components/content-block';
import CustomContentSections from '../components/custom-content-sections';
import References from '../components/references';
import ArticleTaxonomy from '../components/article-taxonomy';

const Overviews = ({ data }) => {
  const overview = data.nodeOverviews;
  const title = overview.title;

  return (
    <PageLayout>
      <SEO title={title} />
      <ArticleLayout
        articleId={overview.articleId}
        articleFileLink={overview.articleFileLink}
      >
        {/* Title section */}
        <ArticleTitleCard
          title={title}
          eyebrow={getArticleCategory(overview)}
        />

        {/* Introductory Note */}
        <ContentBlockComponent
          machine_name={'introductory_note'}
          modifier={'article'}
        />

        {/* Abstract */}
        <ArticleSectionCollapsibleBasic
          title={`Abstract`}
          body={overview?.abstract?.processed}
        />

        {/* Custom sections */}
        <CustomContentSections
          sections={overview?.relationships?.customContent}
        />

        {/* References */}
        <References references={overview?.references} />

        {/* Taxonomy */}
        <ArticleSection id={['article-taxonomy']}>
          <TocTrackedH2 id={['article-taxonomy']}>Taxonomy</TocTrackedH2>
          <ArticleTaxonomy
            intervention_type={overview?.relationships?.interventionType}
            institutions={overview?.relationships?.institutions}
            countriesAndRegions={overview?.relationships?.countriesAndRegions}
            crises={overview?.relationships?.crises}
            issues={overview?.relationships?.issues}
          />
        </ArticleSection>
      </ArticleLayout>
    </PageLayout>
  );
};

export const query = graphql`
  query OverviewsNodeQuery($slug: String!) {
    nodeOverviews(path: { alias: { eq: $slug } }) {
      title
      articleId: id
      articleFileLink: field_link_to_pdf
      relationships {
        author: field_author {
          name: field_name
          bio: field_bio
        }
        interventionType: field_intervention_type {
          name
        }
        institutions: field_institutions {
          name
        }
        contriesAndRegions: field_countries_and_regions {
          name
        }
        crises: field_crises {
          name
        }
        issues: field_issues {
          name
        }
        customContent: field_custom_content {
          title: field_title
          body: field_body {
            processed
          }
          id: id
        }
      }
      abstract: field_abstract {
        processed
      }
      references: field_references {
        title
        url: uri
      }
    }
  }
`;

export default Overviews;
